.language-icon {
  background-size: cover;
  background-position: center center;

  &.language-sv {
    background-image: url('../assets/sv.svg');
    background-position-x: 18%;
  }
  &.language-en {
    background-image: url('../assets/gb.svg');
  }
}
