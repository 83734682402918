.tool-menu-wrapper {
  background: #fff;
  padding: 0.5rem 0;
  min-width: 200px;
  box-shadow: 0 0 10px 0 rgba(96, 109, 123, 0.09);
  border-radius: 5px;
  border: solid 1px #f6f8fa;
  color: $gray-900;
  text-align: left;
  cursor: pointer;

  i {
    padding-left: 5px;
    font-size: 12px;
    width: 30px;
  }
}

.tool-menu-item {
  padding: 0.4rem 0.5rem;
  &:hover {
    background-color: $secondary;
  }
}

.tool-menu-title {
  font-size: 14px;
}

.section-permission-tool-menu {
  max-width: 246px;
  padding: 0 10px;
  .tool-menu-item {
    border-top: solid 1px $blue-300;
    padding: 10px 0;
    &:first-child {
      border-top: none;
    }
  }
  i {
    margin-left: 10px;
  }
  .tool-menu-subtitle {
    margin: 10px;
    color: $gray-700;
    font-size: 12px;
  }
}

.tippy-menu-button {
  @extend .btn;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 100%;
  &:hover {
    background-color: $secondary;
  }
}
