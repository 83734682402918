.columns {
  display: grid;
  column-gap: 1rem;
  & .two {
    grid-template-columns: 1fr 1fr;
  }

  & .three {
    grid-template-columns: 1fr 1fr 1fr;
  }

  & .five {
    grid-template-columns: repeat(5, 1fr);
  }

  &.col-1fr-auto {
    grid-template-columns: 1fr auto;
  }

  &.col-auto-1fr {
    grid-template-columns: auto 1fr;
  }

  &.col-auto-1fr-auto {
    grid-template-columns: auto 1fr auto;
  }
}

.rows {
  display: grid;
  row-gap: 1rem;
  & .two {
    grid-template-rows: 1fr 1fr;
  }

  & .three {
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.gutter-12 {
  row-gap: 12px;
  column-gap: 12px;
}

.justify-self-end {
  justify-self: end;
}
