@use "sass:map";

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Font sizes
.fs-9 {
  font-size: 0.625rem; // 10px
}

.fs-7 {
  font-size: 0.75rem; // 12px
}
.fs-r-1 {
  font-size: 1rem;
}
.fs-r-3 {
  font-size: 3rem;
}

// Flex
.flex-1 {
  flex: 1;
}

// Width
.wr-1 {
  width: 1rem;
}
.min-w-fit {
  min-width: fit-content;
}

.min-wr-15 {
  min-width: 15rem !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.wr-2 {
  width: 2rem;
}

.wr-2-5 {
  width: 2.5rem;
}

.mwr-10 {
  min-width: 10rem;
}

// Forms
.resize-none {
  resize: none;
}

// Position
.top-1 {
  top: 1rem;
}

.end-1 {
  right: 1rem;
}

.end-4 {
  right: 4rem;
}

// Spacing
// Padding or Margin, "r" stands for rem and the number is the actual rems you need

.px-r-1-25 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-r-1-5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-r-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}
.px-r-0-5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-r-0-25 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-r-0-75 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-r-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-r-1-5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-r-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.pb-r-1 {
  padding-bottom: 1rem;
}
.me-r-1 {
  margin-right: 1rem;
}
.pe-r-5 {
  padding-right: 5rem;
}
.mt-r-2-5 {
  margin-top: 2.5rem;
}
.mb-r-2 {
  margin-bottom: 2rem;
}

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  // 1.5rem
  5: $spacer * 3,
  // 3rem
);

/**
 * Create space between children
 */
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  margin-left: map.get($spacers, 3);
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  margin-left: map.get($spacers, 2);
}

.cursor-pointer,
.clickable {
  cursor: pointer;
}

$blues: (
  'blue-100': $blue-100,
  'blue-200': $blue-200,
  'blue-300': $blue-300,
  'blue-400': $blue-400,
  'blue-500': $blue-500,
  'blue-600': $blue-600,
  'blue-700': $blue-700,
  'blue-800': $blue-800,
  'blue-900': $blue-900,
);

$grays: (
  'gray-100': $gray-100,
  'gray-300': $gray-300,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
);

$reds: (
  'red-200': $red-200,
  'red-500': $red-500,
);

$greens: (
  'green-700': $green-700,
);

$yellows: (
  'yellow-100': $yellow-100,
  'yellow-200': $yellow-200,
  'yellow-500': $yellow-500,
);

/**
 * https://getbootstrap.com/docs/5.2/customize/color/#generating-utilities
 */
$utility-colors: map-merge-multiple($blues, $grays, $reds, $greens, $yellows);

// .text-{color}-{level}
$utilities: map-merge(
  $utilities,
  (
    'color':
      map-merge(
        map-get($utilities, 'color'),
        (
          values: map-merge(map-get(map-get($utilities, 'color'), 'values'), ($utility-colors)),
        )
      ),
  )
);

// .bg-{color}-{level}
$utilities: map-merge(
  $utilities,
  (
    'background-color':
      map-merge(
        map-get($utilities, 'background-color'),
        (
          values: map-merge(map-get(map-get($utilities, 'background-color'), 'values'), ($utility-colors)),
        )
      ),
  )
);

// .border-{color}-{level}
$utilities: map-merge(
  $utilities,
  (
    'border-color':
      map-merge(
        map-get($utilities, 'border-color'),
        (
          values: map-merge(map-get(map-get($utilities, 'border-color'), 'values'), ($utility-colors)),
        )
      ),
  )
);

.gap-r-2 {
  gap: 2rem;
}

.gap-r-1 {
  gap: 1rem;
}

.gap-r-0-5 {
  gap: 0.5rem;
}

.draggable-handle {
  cursor: grab !important;
}

.border-dashed {
  border-style: dashed !important;
}

.text-notransform {
  text-transform: none !important;
}

.all-unset {
  all: unset;
}

.aspect-1x1 {
  aspect-ratio: 1;
}

.aspect-sqrt2 {
  aspect-ratio: 1 / 1.4142;
}

.aspect-13x8 {
  aspect-ratio: 13 / 8;
}

.text-left {
  text-align: left !important;
}

.btn-clear {
  border: none;
}

// used for fa icons when you want a centered icon
.fa-center-fix:before {
  margin-left: 1px;
}

.hover-bg-blue-300:hover,
.hover-bg-blue-300:focus {
  background-color: $blue-300 !important;
}
