.confirmationmodal {
  text-align: center;

  .btn-danger {
    color: $white;
    .fa-fw {
      display: none;
    }
    letter-spacing: $btn-letter-spacing;
  }
  .btn-default {
    letter-spacing: $btn-letter-spacing;
  }
  .modal-header {
    border-bottom: none;
    padding: 0;
  }
  .trash {
    margin-top: 1rem;
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
  .close {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
    width: auto;
    height: auto;
  }
  .text-tertiary {
    font-size: $font-size-base;
  }
}
