.change {
  display: grid;
  padding: 18px 20px;
  background-color: #ffffff;
  grid-template-columns: auto 1fr auto;
  column-gap: 15px;
  align-items: center;
  border-bottom: solid 1px $gray-300;
  transition: background-color 0.1s ease-out;

  &.selected {
    background-color: $blue-400;
    transition: unset;
  }

  .change--middle > div:not(:first-child) {
    margin-top: 4px;
  }

  .change--middle--author--name {
    text-transform: capitalize;
    font-size: 14px;
  }

  .change--middle--text {
    font-size: 14px;
  }

  .change--middle--author--date {
    color: $gray-700;
    font-size: 10px;
    font-weight: 300;
  }

  .change--actions {
    align-self: right;

    & > * {
      display: inline-block;
      margin-left: 20px;
    }
  }
}
