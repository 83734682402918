@media print {
  #root {
    margin-top: 0;
  }
}

.pdf-viewer {
  position: relative;
  @media screen {
    display: flex;
  }
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
