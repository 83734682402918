// Settings
$gaby-multiselect-tag-bg: $primary !default; // Background color of tags.
$gaby-multiselect-tag-color: color-contrast($primary) !default; // text color of tags.
$gaby-multiselect-tag-height-scale: 0.6; // The % of multuselect tags height compared to input field;
$gaby-dropdown-item-border-radius: $border-radius !default;
$bootstrap-input-bg: $input-bg;

@use "react-widgets/scss/styles.scss" with (
  $input-height: calc(#{$input-line-height}em + 2 * #{$input-padding-y} + 2 * #{$input-border-width}),
  $input-box-shadow: none,
  $input-bg: white,
  $input-padding-x: $input-padding-x,
  $input-focus-border-color: $input-focus-border-color,
  $input-addon-width: 3em,
  $input-addon-border-color: transparent,
  $widget-border-color: $input-border-color,
  $widget-focus-box-shadow: inset 0 -1.5px 0 $primary,
  $widget-focus-transition: box-shadow 0.15s ease-in-out,
  $widget-hover-border-color: transparent,
  $picker-btn-hover-bg: transparent,
  $picker-btn-active-bg: transparent,
  $picker-btn-color: $input-color,
  $multiselect-tag-color: $gaby-multiselect-tag-color,
  $multiselect-tag-bg: $gaby-multiselect-tag-bg,
  $multiselect-tag-border-radius: $input-border-radius,
  $multiselect-tag-padding-left: 0.75em,
  $multiselect-tag-btn-padding-x: 0.75em,
  $multiselect-tag-btn-padding-y: 0,
  $multiselect-tag-border-color: null,
  $multiselect-tag-height: calc(#{$input-height} * #{$gaby-multiselect-tag-height-scale}),
  $multiselect-tag-gutter: calc(#{$input-height} * (1 - #{$gaby-multiselect-tag-height-scale}) / 2),
  $list-padding-y: $dropdown-padding-y,
  $list-focus-color: $primary,
  $list-selected-bg: $dropdown-link-active-bg,
  $list-option-padding-y: $dropdown-item-padding-y,
  $list-option-padding-x: $dropdown-item-padding-x,
  $list-selected-color: $primary,
  $list-selected-border-color: none,
  $calendar-cell-select-bg: $primary,
  $calendar-cell-select-color: $white,
  $widget-disabled-bg: $gray-100
);

.rw-widget {
  .react-widget-sm {
    min-height: calc(#{$input-line-height}em + 2 * #{$input-btn-padding-y-sm} + 2 * #{$input-border-width});
  }
  .rw-list {
    padding-left: $dropdown-padding-x;
    padding-right: $dropdown-padding-x;
    list-style-type: none;

    .rw-list-option {
      border-radius: $border-radius;
    }
  }
}

// When in read only, override normal look
.rw-state-readonly {
  .rw-multiselect-tag {
    background-color: $gray-700 !important;
    border-color: $gray-700 !important;
  }
  &.rw-state-focus > .rw-widget-container {
    box-shadow: none !important;
  }
}

// Icons in react widgets
.rw-widget {
  &.rw-date-picker {
    .rw-picker-btn {
      svg {
        display: none;
      }
      @extend .far, .fa-calendar !optional;
    }
    &.no-icon {
      .rw-picker-btn:before {
        display: none;
      }
    }
  }
  &.rw-multiselect .rw-picker-caret {
    /* Multiselect only shows caret when in focus. This creates a caret that is always visible */
    @extend .fas, .fa-caret-down !optional;
    &::before {
      line-height: 3;
      padding-left: 1.2rem;
    }
    > * {
      display: none;
    }
  }
}
