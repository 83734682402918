.form {
  .input--holder {
    padding: 1rem;
  }

  label {
    font-weight: 200;
    display: block; // bootstrap override
  }

  input:not([type='submit']) {
    background-color: white;
    border: 1px solid $gray-300;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 5px;
  }
  &.error {
    input:not([type='submit']) {
      border: 1px solid $danger;
    }
  }
}

.input-variant {
  padding: 15px 16px;
  width: 240px;
  font-size: 15px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  color: #84919e;
  &.wider-input {
    width: 330px;
  }
}

.inline-btn {
  height: 15px;
  text-align: right;
  outline: inherit;
  background: none;
  border: none;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #353535;
  text-transform: uppercase;
  position: absolute;
  right: 2rem;
  top: 2rem;

  &:hover {
    cursor: pointer;
  }
}

.form-switch {
  .form-check-label {
    font-weight: 600;
  }
  .form-check-input {
    height: 1.75em;
    cursor: pointer;
  }
}
