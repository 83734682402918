.comment--add-coment > svg:hover {
  cursor: pointer;
}
form {
  margin: 0;
  padding: 0;
}

.comment--add-coment {
  margin-left: 10px;
  padding: 12px;
  border-radius: 50%;
  background: #e0eff9;
  color: #547ba2;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  padding: 20px 0 0 0;
  border: solid 1px $gray-300;

  &.active {
    background-color: $blue-200;
  }
  .comment--header {
    margin: 0 16px 15px 16px;
    &.comment-header-small {
      padding: 0 20px;
      margin: 0 16px 5px 16px;
      .comment--header--icon {
        height: 25px;
        width: 25px;
        line-height: 20px;
        font-size: 8.1px;
        margin: 0 5px 0 0;
      }
      .comment--header--author--name {
        font-size: 12px;
        display: inline-block;
      }
      .comment--header--author--date {
        display: inline;
        margin-left: 5px;
      }
    }
  }

  /*.comment--header > div {
    display: inline-block;
    vertical-align: middle;
  }*/

  .comment--header--icon,
  .comment--header--author {
    display: inline-block;
    vertical-align: middle;
  }

  .comment--header--icon {
    margin: 0 16px 0 0;
    border: solid 3px #ffffff;
  }

  .comment--header--author--name {
    color: #252525;
    font-size: 14px;
  }

  .comment--header--author--date {
    color: #878787;
    font-size: 10px;
    margin-top: 2px;
  }

  .comment--header--menu {
    float: right;
    position: relative;
    display: flex;
    width: 30px;
    justify-content: space-between;

    .comment--popmenu {
      position: absolute;
      right: 10px;
      top: 0;
      padding: 21px 43px 20px 21px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  .comment--draft {
    text-align: right;

    textarea {
      background-color: inherit;
      display: block;
      width: 100%;
      border: 1px solid lightgray;
      margin-bottom: 10px;
      font-family: 'Manrope', sans-serif;
    }

    button {
      background-color: #3f51b5;
      color: #ffffff;
      width: inherit;
      text-align: center;
      border: 0px;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      cursor: pointer;
      outline: none;
    }
  }

  .comment--citation {
    margin: 15px 16px 14px 16px;
    padding: 1px 6px 2px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: $gray-900;
  }

  .comment--messager {
    margin: 14px 4px 15px 1px;
    font-size: 15px;
    line-height: 1.4;
    color: #121212;
  }

  .comment-solved-icon {
    cursor: default;
  }

  .comment--message {
    padding: 0 40px 15px 40px;
    font-size: 14px;
    color: $gray-900;
  }

  .comment--link {
    padding: 0 40px 15px 40px;
    color: $blue-700;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.92px;
    cursor: pointer;
  }

  .comment--response {
    height: 50px;
    margin: 15px 0 0;
    border-top: solid 1px $gray-400;

    &.active {
      border-color: $blue-900;
      input {
        color: $gray-900;
      }
    }

    > form {
      height: 100%;
    }

    button {
      width: 65px;
      height: 15px;
      text-align: right;
      outline: inherit;
      background: none;
      border: none;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #353535;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .comment--responses .comment {
    background: #ffffff;
    border: none;
    padding: 20px 0;
    border-top: solid 1px #dddddd;
    &:first-child {
      border-top: solid 1px $gray-400;
    }
    .comment--message {
      padding: 0 20px;
      line-height: 1.61;
    }
  }
}
