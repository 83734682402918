.button {
  width: inherit;
  text-align: center;
  border: 0px;
  padding: $normalDistance $largeDistance;
  border-radius: 2rem;
  cursor: pointer;
  outline: none;
  transition: all 50ms linear;
  text-transform: uppercase;

  &--small.primary {
    background-color: $primary;
    color: $white;
    height: 40px;
    width: 40px;
    justify-content: center;
    display: flex;
    &:hover {
      background-color: darken($primary, 10%);
    }
    i {
      align-self: center;
    }
  }

  &.btn-secondary {
    color: $blue-700;
  }

  &:hover {
    background-color: darken(#d8d8d8, 10%);
  }

  &.block {
    width: 100%;
  }

  &.clear {
    padding: 12px;
    background-color: transparent;
  }

  &.primary {
    background-color: $primary;
    color: #fff;
    &:hover {
      background-color: darken($primary, 10%);
    }
  }

  &.default {
    background: #fff;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    color: $blue-700;
    font-weight: 500;
    font-size: 12px;
    border-radius: 15px;
    padding: 0.7rem 3rem;
    letter-spacing: 0.92px;
  }

  &.light-blue {
    background: $blue-400;
    padding: 12px 25px;
    color: $blue-700;
    font-size: 12px;
    font-weight: 500;
    height: fit-content;
    margin-right: 0.5rem;
  }

  &.dark {
    background-color: $blue-900;
    color: $white;
    &:hover {
      background-color: darken($blue-900, 10%);
    }
  }

  &.light {
    background-color: $blue-500;
    color: $gray-900;
    &:hover {
      background-color: darken($blue-500, 10%);
    }
  }

  &.margin {
    margin: $smallDistance;
  }

  &.accent {
    background-color: $red-500;
    color: $white;
    &:hover {
      background-color: darken($red-500, 10%);
    }
  }
}

.not-a-button {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
  font: inherit;
}

.action--button,
.fab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  border: 0;
  padding: 0;
  background-color: $blue-400;
  color: $blue-700;
  outline: none;
  cursor: pointer;

  &.clear {
    color: $gray-900;
    background-color: transparent;
  }

  &.animated {
    transition: 150ms all linear;
  }

  &.transform__closed {
    background-color: $red-500;
    transform: rotate(45deg);
  }
}

.btn-circular {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background: $blue-400;
  display: grid;
  text-align: center;
  i {
    margin-top: 9px;
  }
}

.button--group {
  display: flex;
  margin: 1rem;

  &.end {
    justify-content: flex-end;
  }
}

span.link--button {
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn {
  text-transform: uppercase;
}

.btn-primary {
  color: $white;
}

.btn-light {
  color: $body-color;
}

.btn-secondary {
  color: $blue-700;
}

.btn-tippy-dropdown,
.tippy-dropdown-content {
  min-width: 160px;
}
