.modal--wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 4000;
  box-sizing: border-box;

  .header {
    background-color: $blue-200;
    padding: 0 1.5rem;
    height: 70px;
    h3 {
      margin-bottom: 0;
      color: $gray-900;
      align-self: center;
    }
  }

  .body {
    background-color: #fff;
    padding: 1rem 1.5rem;
    &.with-footer {
      grid-template-rows: 1fr auto;
    }
    .form.with-footer {
      display: grid;
      grid-template-rows: 1fr;
    }
  }

  > .modal--overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  > .modal--container {
    z-index: 2;
    position: relative;
    margin: 0 auto;
    display: grid;
    grid-template-rows: auto 1fr auto; // header, content, footer
    background-color: $white;
    border: 1px solid rgba(255, 255, 255, 0.25);
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 920px;
    max-height: 100%;
    min-height: min(620px, 100%);
  }

  &.size-small .modal--container {
    width: 530px;
  }
  &.height-auto .modal--container {
    height: auto;
  }

  &.fill-screen {
    > .modal--container {
      width: 100%;
      height: 100%;
    }
  }
}

.input--holder label {
  display: block; // bootstrap override
}

.modal-btn-group {
  text-align: right;
  margin-top: 1rem;
}

.delete-modal .modal--container {
  width: 345px;
  height: 262px;
  padding: 24px 16px 13px 27px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(96, 109, 123, 0.09);
  border: solid 1px $gray-300;
  background-color: #fff;
  min-width: unset;
  .modal-icon {
    margin-top: 20px;
    font-size: 24px;
  }

  .modal-body-title {
    margin-top: 1rem;
    font-weight: 500;
  }
  .modal-body-text {
    font-size: 14px;
    font-weight: 370;
    line-height: 1.61;
    color: $gray-700;
  }
}

.flex {
  display: flex;
  flex-direction: column;

  .data-sheet-scroll-wrapper {
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: scroll;
    flex-grow: 1;
  }

  .modal-footer  {
    display: block !important;
    margin-bottom: auto t !important;
    width: 100% !important;
  }
}

.text-help {
  color: $danger;
}
.form {
  margin-top: $modal-form-margin-top;
  input {
    color: $gray-800;
  }
  &.error {
    input {
      border-color: $danger;
    }
  }
}
.close {
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;
  width: $modal-close-width;
  height: $modal-close-hight;
  font-size: $h2-font-size;
}
button {
  font-size: 12px;
  letter-spacing: 0.92px;
}
