// Paddings & Margins
$smallDistance: 0.5rem;
$normalDistance: 1rem;
$largeDistance: 2rem;

/* Navbar */
$navbarHeight: 60px;
$subNavbarHeight: 45px;

/* Editor */
$sidebarWidth: 400px;
$sidebarHeight: calc(100vh - #{$navbarHeight});

$sidebarMenuWidth: 55px;
