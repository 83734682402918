.auth-form {
  margin: 2rem auto;

  button {
    height: 60px;
  }

  input {
    height: 65px;
    font-size: 15px;
  }
}

.auth-login {
  flex-direction: row;
  color: $gray-900;
  min-height: 100vh;
}

.auth-login-section {
  flex: 1;
}

.auth-login-section-left {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  @include media-breakpoint-down(md) {
    flex: 1;
  }
  .card {
    background-color: $white;
  }
}

.auth-reset-section {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  @include media-breakpoint-down(md) {
    flex: 1;
  }
  .card {
    background-color: $white;
  }
}

.auth-logo-wrapper {
  width: 460px;
  align-self: flex-start;
  margin-left: 3rem;
  img {
    max-width: 200px;
  }
}

.auth-login-section-right {
  position: relative;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.login-bg {
  background: url('./assets/login-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(8px);
  }
}

.bg-blur {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $blue-800;
  opacity: 0.8;
  height: 100%;
}

.section-right-content-wrapper {
  z-index: 1;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  .section-right-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 600px;
  }
  img {
    width: 500px;
    margin: 0 auto;
    border-radius: 5px;
    max-height: unset;
  }
  h3 {
    font-size: 32px;
  }
  .divider {
    width: 237px;
    height: 5px;
    background: $primary;
    margin: 1rem 5rem 2rem 0;
  }
}

.login-right-bottom-content {
  width: 426px;
  h2 {
    font-size: 40px;
    margin-top: 3rem;
  }
  h4 {
    font-size: 20px;
    line-height: 1.48;
  }
}

.auth-wrapper {
  max-width: 460px;
  @include media-breakpoint-down(sm) {
    .tab-content .card-body {
      padding: 1rem;
    }
  }
  h1 {
    font-size: 48px;
  }
}

.change-password-subtitle {
  font-size: 16px;
  line-height: 1.53;
}
