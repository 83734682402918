.reference-data--list .active {
  background: white;
}

.reference-data--layout {
  .info-card {
    margin-left: 1rem;

    &--header {
      grid-template-columns: auto auto 1fr;

      h3 {
        align-self: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.file-info {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.reference-data--toolbar {
  margin: 20px 0px 20px 0px;
  border-radius: 4px;
  border: solid 1px $gray-400;
  background-color: $blue-300;
  display: flex;
  flex-direction: row;

  button {
    padding: 8px;
    background-color: $blue-300;
    border: none;
    &:hover {
      background-color: $gray-400;
    }
    &.active {
      background-color: $blue-500;
      border-radius: 5px;
    }
  }

  .divider {
    width: 1px;
    background-color: $gray-400;
    height: inherit;
    margin-top: 5px;
    margin-bottom: 5px;
    margin: 0px 10px 0px 10px;
  }
}

.reference-data-dropzones {
  grid-template-columns: 1fr auto 1fr;
  &--upload {
    justify-self: end;
  }
  &--link {
    justify-self: start;
    cursor: pointer;
    p {
      color: #547ba2;
      text-transform: uppercase;
      letter-spacing: 0.92px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.in-doc-message {
  display: inline;
  height: 33px;
  padding: 6px 8px 6px 8px;
  margin-left: 8px;
  border-radius: 3px;
  background: $blue-300;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 550;
  color: $blue-700;
}

.update-selection {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #547ba2;
  letter-spacing: 0.92px;
  cursor: pointer;
}

.file-info div:nth-child(2) div,
.file-info div:nth-child(2) a {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #547ba2;
  letter-spacing: 0.92px;
  cursor: pointer;
}

.data-sheet-scroll-wrapper {
  display: flex;
  flex-direction: row;
  gap: 32px;
  overflow: scroll;
  width: 100%;
  height: 85%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.detail-view {
  border: solid 1px #f6f8fa;
  position: fixed;
  right: 2rem;
  bottom: 0;
  height: min(793px, 90%);
  width: 370px;
  box-shadow: 0 0 10px 0 rgba(96, 109, 123, 0.09);
  border-radius: 5px;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .close-info-btn {
    height: 30px;
    width: 39px;
    background: #fff;
    position: absolute;
    left: -39px;
    top: 4px;
    border-radius: 15px 0 0 15px;
    display: grid;
    cursor: pointer;
    border: solid 1px #f6f8fa;
    border-right: none;
    box-shadow: -10px 0 10px 0 rgba(96, 109, 123, 0.09);
    z-index: 9;
    i {
      place-self: center;
      padding-right: 7px;
      font-size: 12px;
    }
  }

  .title {
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .img-wrapper img {
    height: 230px;
    margin-top: 1rem;
    border-radius: 10px;
  }
  .info {
    font-size: 14px;
    .title {
      text-transform: uppercase;
      color: $gray-700;
      font-size: 12px;
      font-weight: bold;
    }
  }
  &.pictures {
    background: white;
    padding: 1.5rem;
  }
  &.reference-data {
    background: #ffff;

    .list {
      height: inherit;
      overflow: scroll;
      .reference-item {
        cursor: pointer;
        padding: 1rem 1.5rem;
        border-top: 1px solid $gray-300;
        display: flex;
        flex-direction: row;

        &:hover {
          background-color: darken(#fff, 25%);
        }
        &:last-child {
          border-bottom: 1px solid $gray-300;
        }
        &.active-reference-item {
          background: $blue-300;
          color: $blue-800;
          padding: 1rem 0.5rem;
          &:hover {
            background-color: $gray-400;
          }
          .date-text {
            padding-left: 1rem;
          }
          i {
            padding-top: 4px;
          }
        }

        .details-button {
          width: 40px;
          height: 40px;
          display: grid;
          justify-content: center;
          align-content: center;
          border-radius: 50%;
          &:hover {
            background-color: $blue-700;
          }
        }
      }
    }

    .file-name {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 5px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-details-item-wraper {
      column-gap: 5px;
    }

    .details--info {
      .area-- {
        &title {
          display: flex;
          flex-direction: row;
          margin: $normalDistance;
          padding-bottom: $normalDistance;
          border-bottom: 1px solid $blue-300;

          .back {
            width: 40px;
            height: 40px;
            display: grid;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: $smallDistance;
          }
        }

        &main {
          margin: $normalDistance;

          h3 {
            color: $gray-700;
          }

          .pill-box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .pill {
              background-color: $blue-300;
              border-radius: 4px;
              margin-right: $smallDistance;
              margin-bottom: $smallDistance;
              padding: $smallDistance;
              text-transform: uppercase;
              color: $blue-700;
            }
          }
        }
      }

      .description-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        dt {
          width: 33%;
          text-transform: capitalize;
          color: $gray-700;
          margin-bottom: $normalDistance;
        }
        dd {
          margin-left: auto;
          width: 66%;
        }
      }
    }
  }
}
