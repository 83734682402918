/********************
 * XPLIR colors
 ********************/

// Blues
$blue-100: #f6f8fa;
$blue-200: #f1f5f9;
$blue-300: #e9f3fa;
$blue-400: #e0eff9;
$blue-500: #c7ddec;
$blue-600: #7694b2;
$blue-700: #547ba2;
$blue-800: #085dad;
$blue-900: #36526f;

// Grays
$gray-100: #f6f7f9;
$gray-300: #e1e7ed;
$gray-600: #adb3b8;
$gray-700: #84919e;
$gray-800: #48515b;
$gray-900: #1e2533;

// Greens
$green-100: #edfbf6;
$green-500: #a5e66d;
$green-700: #4db894;

// Reds
$red-200: #fbd8d8;
$red-500: #bc2020;

// Yellow
$yellow-100: #fff6df;
$yellow-200: #fff0ca;
$yellow-500: #ffd56e;

/********************
 * Bootstrap config
 ********************/
html {
  font-size: 16px; // 1rem
}

$enable-dark-mode: false;

$spacer: 1rem;

// Theme colors
$primary: $green-700;
$secondary: $blue-300;
$danger: $red-500;
$success: $green-500;
$warning: $yellow-500;
$info: $blue-800;
$dark: $gray-900;
$light: white;

// General
$body-bg: $blue-100;
$body-color: $gray-900;
$link-color: $body-color;

// Typography
$font-size-base: 0.875rem; // 14px
$h1-font-size: 1.625rem; //   26px
$h2-font-size: 1rem; //       16px
$h3-font-size: 0.9375rem; //  15px
$h4-font-size: 0.8125rem; //  13px
$h5-font-size: 0.75rem; //    12px
$h6-font-size: 0.6875rem; //  11px

$headings-font-weight: 600;
$h1-font-weight: 700;
$font-weight-base: 370;
$font-weight-bolder: 800;

// Cards
$card-border-color: unset;
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;
$card-border-radius: 6px;
$card-border-color: $gray-300;

// Accordion
$accordion-padding-x: 1rem;
$accordion-bg: $blue-200;
$accordion-button-bg: white;
$accordion-button-active-bg: $blue-200;
$accordion-color: $gray-900;
$accordion-button-active-color: $gray-900;
$accordion-button-icon: url('data:image/svg+xml,<svg></svg>');
$accordion-button-active-icon: url('data:image/svg+xml,<svg></svg>');

// Badges
$badge-font-weight: 300;
$badge-color: $gray-800;
$badge-padding-y: 0;
$badge-padding-x: 0.3rem;

// Nav tabs
$nav-tabs-border-radius: 0.5rem;
$nav-tabs-link-active-bg: white;
$nav-tabs-link-active-color: $gray-900;

// Nav pills
$nav-pills-link-active-bg: $info;

// Modals
$modal-footer-border-width: 0;
$modal-content-border-width: 0px;
$modal-content-border-radius: 5px;
$modal-inner-padding: 1.5rem;
$modal-header-padding: 1.5rem;
$modal-close-width: 0.625rem;
$modal-close-hight: 1rem;
$modal-form-margin-top: 3.875rem;

// Input
$input-bg: white;
$input-padding-y: 1.15rem;
$input-padding-x: 1.15rem;
$input-border-color: $gray-300;
$input-focus-border-color: $primary;
$input-font-size: 1rem;
$input-line-height: 1.125;
$input-focus-box-shadow: none;
$input-disabled-color: $gray-600;
$input-btn-padding-y-sm: 0.45rem;

$form-label-margin-bottom: 0.5rem;
$form-label-font-weight: 600;

// Switch
$form-switch-width: 3.2em;
$form-check-input-checked-bg-color: $green-500;
$form-check-input-checked-border-color: $green-500;
$form-switch-focus-color: $green-500;

// Label
$form-label-font-size: 1rem;
$form-label-font-weight: 600;
$form-label-font-size: 0.875rem;
$form-label-margin-bottom: 0.5rem;

// Buttons
$btn-padding-y: 0.825rem;
$btn-padding-x: 1.5rem;
$btn-font-size: 0.75rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 0.75rem;
$btn-font-size-sm: 0.75rem;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-font-weight: 600;
$btn-border-radius: 2rem;
$btn-letter-spacing: 0.92px;

// Dropdown
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $body-color;
$dropdown-item-padding-y: $spacer;
$dropdown-link-active-color: $body-color;
$dropdown-link-active-bg: $blue-200;
$dropdown-link-hover-bg: $blue-200;

// HR
$hr-opacity: 1;
