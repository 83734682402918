body,
a,
a:hover,
a:visited {
  letter-spacing: 0.02em;
}

h1 {
  font-weight: $h1-font-weight;
}

.subheading {
  text-transform: uppercase;
  color: $gray-900;
  font-weight: 700;
  font-size: 0.75rem;
  letter-spacing: 1px;
}

a {
  text-decoration: none;
}

.text {
  &--bold {
    font-weight: 600;
  }

  &--faded,
  &--faded:visited {
    color: $blue-400;
  }

  &--pagination {
    color: $gray-700;
    font-size: 12px;
  }

  &--tertriary {
    color: $gray-700;
  }
}

.font-15 {
  font-size: 15px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.text-disabled {
  color: $gray-700 !important;
}
