.loader {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;

  height: 100px;
  width: 100px;
  margin-bottom: 1rem;
  margin-top: 1rem;

  > div {
    position: absolute;
    top: 0;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: $gray-300;
    border-radius: 50%;
    animation: bounce 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  > div:nth-child(1) {
    top: 0;
    left: 0;
    animation-delay: 0s;
  }

  > div:nth-child(2) {
    top: 0;
    left: 30;
    animation-delay: 0.25s;
  }

  > div:nth-child(3) {
    top: 0;
    left: 60px;
    background-color: $gray-300;
    animation-delay: 0.5s;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
  }
}
